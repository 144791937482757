.modalContainer {
    & .title {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      text-align: left;
      color: #000;
      margin: 0;
      @media (max-width: 767px) {
        margin: 32px 0 0;
        font-size: 20px;
        line-height: 30px;
      }
    }
    & .description {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      color: #000;
      margin: 16px 0 24px;
    }
    & .twoButtons {
      display: flex;
      gap: 24px;
      & .button {
        text-decoration: unset;
        width: calc((100% - 16px) / 2);
      }
    }
  }