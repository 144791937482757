.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.radioField{
  margin-bottom: 24px;
}
.radioplaceholder{
  padding-bottom: 6px;
  display: block;
}
& ul{
  & li{
    margin-bottom: 8px;
  }
}